.App {
  text-align: center;
}
body {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
}

h1, h2, h3 {
  font-weight: normal;
}


.link, .blog-link {
  text-decoration: none;
  color: purple;
  display: inline-block;
  width: 25%;
  cursor: default;
  margin: 10px 0;
}
nav {
  background-color: rgb(184, 184, 236);
}

.blog-nav {
  top: 100px;
}

.blog-link {
  display: inline-block;
  width: 33%;
  padding: 10px 0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
